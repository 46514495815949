export const getDomainCookie = (domain) => {
  if (!domain) return ''
  const domainNew = domain.replace(/^https?:\/\//, '').split(':')[0]
  const hostname = domainNew.split('.')
  hostname.reverse()
  return `.${hostname[1]}.${hostname[0]}`
}

export const setCookie = (name, value, domain, path = '/') => {
  document.cookie = `${name}=${value || ''}; path=${path};domain=${getDomainCookie(domain)}`
}

export const getCookie = (cookieName) => {
  const cookie = {}
  document.cookie.split(';').forEach(function (el) {
    const [key, value] = el.split('=')
    cookie[key.trim()] = value
  })
  return cookie[cookieName]
}
