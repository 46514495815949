import React from 'react'
import ReactSelect from 'react-select'

import Label from '../Label'
import HelperText from '../HelperText'

import './style.scss'

type valueProps = {
  value: string
  label: string
}

type Props = {
  id?: string
  label?: string
  options?: any
  groupped?: boolean
  error?: any
  disabled?: boolean
  onBlur?: (e: any) => void
  onChange?: (e: any) => void
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  helperText?: string
  isValid?: boolean
  className?: string
  labelClassName?: string
  text_no_results?: string
  placeholder?: string
  value?: valueProps
}

const customStyles = {
  input: (provided: any) => ({ ...provided, paddingLeft: 4 }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: 0
  }),
  singleValue: (provided: any) => ({ ...provided, paddingLeft: 4 }),
  container: (provided: any) => ({
    ...provided,
    padding: 0,
    minHeight: 42
  }),
  control: (provided: any) => ({
    ...provided,
    paddingTop: 1,
    paddingBottom: 1,
    boxShadow: 'none',
    height: 42,
    minHeight: 42,
    '&:hover': {
      borderColor: 'hsl(0%, 0%, 70%)'
    }
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    width: 0
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#9EA4AC',
    padding: 4
  }),
  option: (styles: any, { isSelected, errorMessage }: any) => ({
    ...styles,
    fontWeight: 'normal',
    backgroundColor: isSelected ? '#E6E9ED' : undefined,
    color: isSelected ? '#191C1F' : undefined,
    borderColor: errorMessage ? 'var(--red)' : undefined,
    ':active': {
      ...styles[':active'],
      backgroundColor: isSelected ? '#E6E9ED' : undefined
    }
  })
}

const Select = React.forwardRef(
  (
    {
      id,
      label,
      options,
      error,
      disabled,
      onChange,
      onFocus,
      helperText,
      isValid,
      className,
      labelClassName,
      placeholder,
      value
    }: Props,
    ref: any
  ) => {
    return (
      <div className={`hot-form ${className}`}>
        {label && <Label className={`hot-form__label ${labelClassName}`} text={label} isChecked={isValid} />}
        <ReactSelect
          id={id}
          placeholder={placeholder}
          className={`${className} ${error ? 'is-invalid' : ''}`}
          options={options}
          ref={ref}
          isDisabled={disabled}
          value={value}
          onChange={onChange}
          styles={customStyles}
          onFocus={onFocus}
        />
        <HelperText error={error} helperText={helperText} />
      </div>
    )
  }
)

Select.displayName = 'Select'
export default React.memo(Select)
