import type { HcTagContext } from '@hotmart-org-ca/cosmos-web/dist/web-components/types/components/hc-tag/hc-tag'

export const AVAILABLE_PAYMENT_METHOD = {
  BOLETO: 'BOLETO',
  PAYPAL: 'PAYPAL',
  TRANSFERENCIA_ONLINE_BANCO_BRASIL: 'TRANSFERENCIA_ONLINE_BANCO_BRASIL',
  TRANSFERENCIA_ONLINE_BRADESCO: 'TRANSFERENCIA_ONLINE_BRADESCO',
  TRANSFERENCIA_ONLINE_ITAU: 'TRANSFERENCIA_ONLINE_ITAU',
  CARTAO_CREDITO_VISA: 'CARTAO_CREDITO_VISA',
  CARTAO_CREDITO_MASTERCARD: 'CARTAO_CREDITO_MASTERCARD',
  CARTAO_CREDITO_AMERICAN_EXPRESS: 'CARTAO_CREDITO_AMERICAN_EXPRESS',
  CARTAO_CREDITO_AURA: 'CARTAO_CREDITO_AURA',
  CARTAO_CREDITO_DINERS: 'CARTAO_CREDITO_DINERS',
  CARTAO_CREDITO_HIPERCARD: 'CARTAO_CREDITO_HIPERCARD',
  CARTAO_CREDITO_ELO: 'CARTAO_CREDITO_ELO',
  HOTMART: 'HOTMART',
  BANK_DEBIT: 'BANK_DEBIT',
  CARTAO_CREDITO_DISCOVER: 'CARTAO_CREDITO_DISCOVER',
  SEPA_DIRECT_DEBIT: 'SEPA_DIRECT_DEBIT',
  MULTIBANCO: 'MULTIBANCO',
  GOOGLE_PAY: 'GOOGLE_PAY',
  BALOTO: 'BALOTO',
  OXXO: 'OXXO',
  CUPON_DE_PAGO: 'CUPON_DE_PAGO',
  SAMSUNG_PAY: 'SAMSUNG_PAY',
  PAGO_EFECTIVO: 'PAGO_EFECTIVO',
  BACS_DIRECT_DEBIT: 'BACS_DIRECT_DEBIT',
  PICPAY: 'PICPAY',
  FINANCED_INSTALLMENT_ADYEN_ONEY: 'FINANCED_INSTALLMENT_ADYEN_ONEY',
  DIRECT_BANK_TRANSFER_ADYEN_SOFORT: 'DIRECT_BANK_TRANSFER_ADYEN_SOFORT',
  FINANCED_INSTALLMENT_ADYEN_ONEY_3X: 'FINANCED_INSTALLMENT_ADYEN_ONEY_3X',
  FINANCED_INSTALLMENT_ADYEN_ONEY_4X: 'FINANCED_INSTALLMENT_ADYEN_ONEY_4X',
  FINANCED_INSTALLMENT_ADYEN_ONEY_6X: 'FINANCED_INSTALLMENT_ADYEN_ONEY_6X',
  FINANCED_INSTALLMENT_ADYEN_ONEY_10X: 'FINANCED_INSTALLMENT_ADYEN_ONEY_10X',
  FINANCED_INSTALLMENT_ADYEN_ONEY_12X: 'FINANCED_INSTALLMENT_ADYEN_ONEY_12X',
  PIX: 'PIX',
  FINANCED_BILLET: 'FINANCED_BILLET',
  HYBRID: 'HYBRID',
  IN_APP_PURCHASE: 'IN_APP_PURCHASE',
  APPLE_PAY: 'APPLE_PAY',
  NEQUI: 'NEQUI',
  MERCADO_PAGO: 'MERCADO_PAGO',
  SEQURA: 'SEQURA',
  MACH_PAY: 'MACH_PAY',
  SERVIPAG: 'SERVIPAG',
  BANCOLOMBIA: 'BANCOLOMBIA',
  MBWAY: 'MBWAY',
  YAPE: 'YAPE'
} as const

export const FAILURE_MESSAGE_DATAHUB = {
  CONTENT_LINK_EMPTY: 'O link do botão do conteúdo está vazio!',
  DOWNLOAD_LINK_EMPTY: 'O link do download de arquivo está vazio!',
  DOWNLOAD_CONFIRMATION_EMPTY: 'O link de confirmação download de arquivo está vazio!'
}

export const DATA_LAYER = {
  PAGE_VIEW: {
    PURCHASE: {
      EVENT: 'pageview',
      SECTION: 'Purchase:Consumer',
      SUBSECTION: 'MyPurchases',
      LOGGED: 'true'
    }
  },
  INTERACTION: {
    CLICK_PRODUCT: {
      EVENT: 'interaction',
      CATEGORY: 'Purchase:Consumer',
      ACTION: 'Click:Product',
      LABEL: 'Product'
    },
    CLICK_PRODUCT_DIRECT: {
      EVENT: 'interaction',
      CATEGORY: 'Hub:Access',
      ACTION: 'Click:Request',
      LABEL: 'card-list-direct-access'
    },
    ACCESS_CONTENT: {
      EVENT: 'interaction',
      CATEGORY: 'Hub:Access',
      ACTION: 'Click:Request',
      LABEL: 'button-access-content-in-product-detail'
    },
    MARKEPLACE_BANNER: {
      EVENT: 'interaction',
      CATEGORY: 'Marketplace:Consumer',
      ACTION: 'Click:MarketplaceBanner',
      LABEL: 'click_marketplace_banner'
    }
  }
}

export const LANGUAGES = {
  AR: 'ar',
  DE: 'DE',
  EN: 'EN',
  ES: 'ES',
  FR: 'FR',
  IT: 'IT',
  JA: 'JA',
  PT_BR: 'PT_BR'
}

export const HOTMART_SITE_LOCALES = {
  [LANGUAGES.EN]: 'en',
  [LANGUAGES.ES]: 'es',
  [LANGUAGES.FR]: 'fr',
  [LANGUAGES.PT_BR]: 'pt-br'
}

export const DEVICE_TYPE = {
  IOS: 'app_ios',
  ANDROID: 'app_android',
  TABLET: 'app_tablet',
  DESKTOP: 'site_desktop'
}

export const DEVICE_STORE_LINK = {
  IOS: '//itunes.apple.com/br/app/hotmart/id1066849827?ls=1&mt=8',
  ANDROID: '//play.google.com/store/apps/details?id=com.hotmart.sparkle'
}

export const PURCHASE_STATUS = {
  RECOVERED: 'Reclamado',
  REFUNDED: 'Reembolsado',
  ARCHIVED: 'ARCHIVED',
  UNARCHIVED: 'UNARCHIVED'
}

export const PAYMENT_MODE = {
  ASSINATURA: 'ASSINATURA'
}

export const PAYMENTS_SUBSCRIPTIONS_ROUTES = {
  root: '/payments/:purchaseId',
  regularizePayment: 'regularize',
  errorRegularizePayment: 'regularize/error',
  regularizeByPix: 'pix',
  regularizeByBillet: 'billet',
  regularizeByCreditCard: 'credit-card',
  creditCardPaymentError: 'regularize/credit-card/error',
  creditCardPaymentSuccess: 'regularize/credit-card/success/:transaction',
  paymentView: 'view/:transaction',
  processingPayment: 'processing/:paymentType',
  alterPaymentMethod: 'alter-method',
  changeBillingDate: 'change-billing-date',
  recurrencesHistory: 'recurrences-history'
}

export enum ContextEnum {
  'SUCCESS' = 'success',
  'INFO' = 'info',
  'DANGER' = 'danger',
  'WARNING' = 'warning',
  'NEUTRAL' = 'neutral'
}

export const UPCOMING_CHARGE_TAG = {
  FUTURE_INSTALLMENT: {
    contextTag: ContextEnum.NEUTRAL,
    text: 'payment_management.billing_status.next_billing',
    icon: ''
  },
  WAITING_PAYMENT: {
    contextTag: ContextEnum.WARNING,
    icon: 'alarm-clock',
    text: 'payment_management.billing_status.awaiting'
  },
  PRINTED_BILLET: {
    contextTag: ContextEnum.WARNING,
    icon: 'alarm-clock',
    text: 'payment_management.billing_status.awaiting'
  },
  COMPLETE: {
    contextTag: ContextEnum.SUCCESS,
    icon: 'circle-check',
    text: 'payment_management.billing_status.complete'
  },
  APPROVED: {
    contextTag: ContextEnum.SUCCESS,
    icon: 'circle-check',
    text: 'payment_management.billing_status.complete'
  },
  OVERDUE: {
    contextTag: ContextEnum.DANGER,
    text: 'payment_management.billing_status.delayed'
  }
}

export const SENTRY_TAG = {
  HOST: {
    name: 'host',
    release: `hot-hub-ui-host`,
    dsn: 'https://6d9009ddaa624fbca9c2ce85c7709f8b@o49094.ingest.sentry.io/4505467367260160'
  },
  PURCHASE: {
    name: 'purchase',
    release: `hot-hub-ui-purchase`,
    dsn: 'https://4a49f08450c44cbabae10e1ca56d5960@o49094.ingest.sentry.io/4505546626957312'
  },
  MY_ACCOUNT: {
    name: 'my_account',
    release: `hot-hub-ui-my-account`,
    dsn: 'https://c641db14be7b41f0a381ada235c12007@o49094.ingest.sentry.io/4505546640588800'
  },
  SUBSCRIPTION: {
    name: 'subscription',
    release: `hot-hub-ui-subscription`,
    dsn: 'https://f1422703618544d0bcf50f6cc2618f29@o49094.ingest.sentry.io/4505550658732032'
  }
}

export const STATUS_MAP_TAG_CONTEXT = {
  DANGER: 'danger',
  INFO: 'brand',
  NEUTRAL: 'neutral',
  SUCCESS: 'success',
  WARNING: 'warning'
} as Record<'DANGER' | 'INFO' | 'NEUTRAL' | 'SUCCESS' | 'WARNING', HcTagContext>

export enum Environments {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development'
}

export enum PossibleStorages {
  localStorage = 'localStorage',
  inMemoryStorage = 'inMemoryStorage',
  sessionStorage = 'sessionStorage',
  default = 'localStorage'
}
