import { atom } from 'recoil'

export function DefaultToast({
  context = '',
  message = '',
  onTimeout = null,
  onDismiss = null,
  translationComponent = null
} = {}) {
  this.context = context
  this.message = message
  this.onTimeout = onTimeout
  this.onDismiss = onDismiss
  this.translationComponent = translationComponent
}

const ToastState = atom({
  key: 'toastState',
  default: new DefaultToast()
})

export default ToastState
