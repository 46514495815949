import { API, APIServers } from '../constants'

export const hubV1 = `${APIServers.hub}/${API.hub}/purchase`
export const hubV2 = `${APIServers.hub}/${API.hubV2}/purchase`
export const hubV3 = `${APIServers.hub}/${API.hubV3}/purchase`
export const marketplaceUserProfileUser = `${APIServers.marketplace}/${API.marketplaceUserProfile}/user`
export const marketplaceUserAddress = `${APIServers.marketplace}/${API.marketplaceUserProfile}/address`
export const marketplacePurchaseSubscriber = `${APIServers.marketplace}/${API.marketplacePurchase}/subscribers`
export const cep = `${APIServers.cep}/${API.cep}`
export const document = `${APIServers.accounts}/${API.document}`
export const security = `${APIServers.security}/${API.security}`
export const features = `${APIServers.accounts}/${API.features}`
export const dataExport = `${APIServers.accounts}/${API.dataExport}`
export const shopper = `${APIServers.hub}/${API.hub}/shopper`
export const ask = `${APIServers.ask}/${API.ask}`
export const space = `${APIServers.space}/${API.space}/spaces`
export const refunds = `${APIServers.refunds}`
export const bkfSupport = `${APIServers.bkfSupport}`
export const hotpayInvoice = `${APIServers.hotpayInvoice}/${API.hotpayInvoice}`
export const abTest = `${APIServers.abTest}/${API.abTest}`
export const eticket = `${APIServers.eticket}/${API.eticket}`
export const content_search = `${APIServers.content_search}/${API.content_search}`
export const account = `${API.account}`
export const shipmentsV1 = `${APIServers.shipments}/${API.shipmentsV1}`
export const subscriptionEngine = `${API.subscriptionEngine}`
export const hubV2Subscription = `${APIServers.hub}/${API.hubV2}/subscription`
export const spaceV1 = `${APIServers.space}/${API.spaceV1}`
