import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import Modal from '../../Modal'

import './style.scss'

type IErrorGeneric = {
  isModal?: boolean
}

function ErrorGeneric({ isModal }: IErrorGeneric) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const contentRender = () => {
    return (
      <div className="error__block">
        <img src={'images/error_generic.png'} />
        <div className="error__title">{t('errors.generic_title')}</div>
        <div className="error__subtitle">{t('errors.generic_subtitle')}</div>
      </div>
    )
  }

  if (isModal) {
    return (
      <Modal open={true} onClose={() => navigate('..')}>
        {contentRender()}
      </Modal>
    )
  }

  return <>{contentRender()}</>
}
export default ErrorGeneric
