import { useEffect } from 'react'

const useKeydown = (key, onFunc) => {
  useEffect(() => {
    const detectKeyDown = (e) => {
      if (key === e.key) {
        onFunc()
      }
    }

    document.addEventListener('keydown', detectKeyDown, true)
    return () => {
      document.removeEventListener('keydown', detectKeyDown, true)
    }
  }, [key, onFunc])
}

export default useKeydown
