import { useEffect } from 'react'

const useScript = (url, id) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.id = id

    if (!document.getElementById(id)) {
      document.body.appendChild(script)
    }
  }, [url, id])
}

export default useScript
