import { useEffect } from 'react'

const useAlertDismiss = (ref, onDismiss) => {
  useEffect(() => {
    const handleDismiss = (e) => {
      if (onDismiss) {
        onDismiss(e)
      }
    }

    const current = ref.current

    if (current) {
      current.addEventListener('dismiss', handleDismiss)
    }
    return () => {
      if (current) {
        current.removeEventListener('dismiss', handleDismiss)
      }
    }
  })
}

export default useAlertDismiss
