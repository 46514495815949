import React, { useState, useMemo } from 'react'

import '@hotmart/cosmos/dist/form/form.css'
import '@hotmart/cosmos/dist/form/input-default.css'
import '@hotmart/cosmos/dist/input-group/input-group.css'
import '@hotmart/cosmos/dist/tooltip'

import Icons from '../Icons'

import Label from '../Label'
import HelperText from '../HelperText'

import './input.scss'

type Props = {
  id?: string
  type?: string
  label: string
  startContent?: boolean
  endContent?: boolean
  error?: any
  helperText?: string
  isValid?: boolean
  className?: string
  labelClassName?: string
  inputClassName?: string
  prependClassName?: string
  // eslint-disable-next-line no-unused-vars
  onFocus?: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  onBlur?: (e: any) => void
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void
  inputHint?: string
  placeholder?: string
  labelToolTipText?: string
  iconName?: string
  iconPrefix?: string
  inputPrefix?: string
  inputSuffix?: string
  value?: string
  disabled?: boolean
  maxLength?: number
}

const Input = React.forwardRef(
  (
    {
      id,
      type,
      label,
      startContent,
      endContent,
      error,
      helperText,
      isValid,
      className,
      labelClassName,
      inputClassName,
      prependClassName,
      onFocus,
      onBlur,
      onChange,
      inputHint,
      placeholder,
      labelToolTipText,
      iconName,
      iconPrefix,
      inputPrefix,
      inputSuffix,
      value,
      disabled,
      maxLength
    }: Props,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const [inputFocused, setInputFocused] = useState(false)
    const [showHint, setShowHint] = useState(!!inputHint)

    const toggleInputFocused = () => setInputFocused(!inputFocused)

    const hintElement = useMemo(() => {
      return (
        <span className="hint-element">
          <span className="placeholder-text">{placeholder}</span>
          <span className="hint-container">
            <hot-tooltip content={inputHint} position="top">
              <Icons iconName={iconName || 'info-circle'} prefix={iconPrefix || 'fal'} />
            </hot-tooltip>
          </span>
        </span>
      )
    }, [placeholder, inputHint, iconName, iconPrefix])

    return (
      <div className={`hot-form input ${className}`}>
        {label && (
          <Label
            className={`hot-form__label ${labelClassName}`}
            text={label}
            tooltipText={labelToolTipText}
            isChecked={isValid}
          />
        )}
        {/** Default Input */}
        {!inputHint && !startContent && !endContent && (
          <div className={`${inputPrefix || inputSuffix ? 'input_prefix_suffix_block' : ''}`}>
            {inputPrefix && <span className="prefix">{inputPrefix}</span>}
            <input
              ref={ref}
              type={type || 'text'}
              className={`hot-form__input ${error ? 'is-invalid' : ''} ${inputClassName}`}
              id={id}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              placeholder={placeholder}
              value={value || ''}
              disabled={disabled}
              maxLength={maxLength}
            />
            {inputSuffix && <span className="suffix">{inputSuffix}</span>}
          </div>
        )}
        {/** Input with prepend or append items */}
        {(inputHint || startContent || endContent) && (
          <hot-input-group>
            {startContent && (
              <span
                className={`hot-input-group__prepend ${prependClassName}
                ${inputFocused ? `${prependClassName}-focus` : ''}
                ${error ? `${prependClassName}-error` : ''}
                `}
              >
                {startContent}
              </span>
            )}
            <input
              ref={ref}
              type={type || 'text'}
              className={`hot-form__input ${error ? 'is-invalid' : ''} ${inputClassName}`}
              placeholder={placeholder}
              onFocus={(e) => {
                if (onFocus) {
                  onFocus(e)
                }
                toggleInputFocused()
              }}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(e)
                }
                toggleInputFocused()
              }}
              onChange={(e) => {
                if (inputHint) {
                  setShowHint(!e?.target?.value)
                }
                if (onChange) {
                  onChange(e)
                }
                toggleInputFocused()
              }}
              id={id}
              value={value || ''}
              disabled={disabled}
              maxLength={maxLength}
            />

            {showHint && hintElement}
          </hot-input-group>
        )}

        <HelperText error={error} helperText={helperText} />
      </div>
    )
  }
)

Input.displayName = 'Input'
export default Input
