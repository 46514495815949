import { EVENT_ACTION, EVENT_ENTITY, sendDataHub, LANGUAGES } from '@hotmart/hub-components'
import { useMediaQuery } from '@hotmart/hub-components/src/custom-hooks'

type Props = {
  locale: string
  ucode: string
}

function FireBanner({ locale, ucode }: Props) {
  const { isMobile } = useMediaQuery()
  const typeBanner = isMobile ? 'responsivo' : 'web'
  const newLocale = [LANGUAGES.PT_BR, LANGUAGES.EN, LANGUAGES.ES].includes(locale) ? locale : LANGUAGES.EN
  const banner = `/images/fire/${typeBanner}_${newLocale.toLowerCase()}.png`
  const utm_locale = LANGUAGES.PT_BR === locale ? 'br' : newLocale.toLowerCase()
  const fireLocale = LANGUAGES.PT_BR === locale ? '' : newLocale.toLowerCase()

  const openFireSite = () => {
    const paramInitial = 'utm_medium=internal&utm_source=hub'
    const paramEnd = `${paramInitial}&utm_campaign=${utm_locale}_sales_fire_banner_6-lote-escassez-${typeBanner}`
    sendDataHub(EVENT_ENTITY.USER, EVENT_ACTION.CLICK, '1.0', {
      userCode: ucode,
      operationType: `banner_fire`,
      operationValue: locale
    })

    window.open(`${process.env.SITE_FIRE}/${fireLocale}?${paramEnd}`, '_blank')
  }
  return (
    <img
      src={banner}
      style={{ cursor: 'pointer', width: '100%', borderRadius: '0.5rem', marginBottom: '2rem' }}
      onClick={() => openFireSite()}
    />
  )
}

export default FireBanner
