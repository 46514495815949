import { useEffect, useState } from 'react'

function getMediaQuery(query: string) {
  return window?.matchMedia(query)
}

const COSMOS_SM_BREAKPOINT_IN_PX = 767
const COSMOS_MD_BREAKPOINT__IN_PX = 768
const COSMOS_LG_BREAKPOINT_IN_PX = 1199
const COSMOS_XL_BREAKPOINT_IN_PX = 1200

const DESKTOP_QUERY = `(min-width: ${COSMOS_XL_BREAKPOINT_IN_PX}px)`
const TABLET_QUERY = `(min-width: ${COSMOS_MD_BREAKPOINT__IN_PX}px) and (max-width: ${COSMOS_LG_BREAKPOINT_IN_PX}px)`
const MOBILE_QUERY = `(max-width: ${COSMOS_SM_BREAKPOINT_IN_PX}px)`

export default function useMediaQuery() {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)
  const [isDesktop, setIsDesktop] = useState<boolean>(false)

  useEffect(() => {
    const isDesktopMediaQuery = getMediaQuery(DESKTOP_QUERY)
    const isTabletMediaQuery = getMediaQuery(TABLET_QUERY)
    const isMobileMediaQuery = getMediaQuery(MOBILE_QUERY)

    setIsDesktop(isDesktopMediaQuery.matches)
    setIsTablet(isTabletMediaQuery.matches)
    setIsMobile(isMobileMediaQuery.matches)

    const handleMobileSizeChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setIsMobile(true)
        setIsTablet(false)
        setIsDesktop(false)
        return
      }
      setIsMobile(false)
    }

    const handleTabletSizeChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setIsTablet(true)
        setIsMobile(false)
        setIsDesktop(false)
        return
      }
      setIsTablet(false)
    }

    const handleDesktopSizeChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setIsDesktop(true)
        setIsMobile(false)
        setIsTablet(false)
        return
      }
      setIsDesktop(false)
    }

    isDesktopMediaQuery.addEventListener('change', handleDesktopSizeChange)
    isTabletMediaQuery.addEventListener('change', handleTabletSizeChange)
    isMobileMediaQuery.addEventListener('change', handleMobileSizeChange)

    return () => {
      isDesktopMediaQuery.removeEventListener('change', handleDesktopSizeChange)
      isTabletMediaQuery.removeEventListener('change', handleTabletSizeChange)
      isMobileMediaQuery.removeEventListener('change', handleMobileSizeChange)
    }
  }, [])

  return { isMobile, isTablet, isDesktop }
}
