import React from 'react'
import { library, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import lightIcons from '../assets/font-icons/light'
import solidIcons from '../assets/font-icons/solid'

library.add(...lightIcons, ...solidIcons)

type Props = { iconName: string; prefix: string; className?: string; onClick?: () => void; dataTestId?: string }

const Icons = (props: Props) => {
  return (
    <FontAwesomeIcon
      data-testid={props.dataTestId}
      className={props.className}
      icon={[props.prefix as IconPrefix, props.iconName as IconName]}
      onClick={props.onClick}
    />
  )
}
export default Icons
