export function loadScript(url: string, scriptId: string) {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      resolve('script already exist')
    } else {
      const scriptElement = document.createElement('script')
      if (scriptId) scriptElement.id = scriptId
      scriptElement.src = url
      scriptElement.type = 'text/javascript'
      scriptElement.async = true
      scriptElement.defer = true
      scriptElement.onload = resolve
      scriptElement.onerror = reject

      document.head.appendChild(scriptElement)
    }
  })
}
