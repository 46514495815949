import { ShopperPurchaseResponse } from '@hotmart/hub-api-client/types'
import { atom } from 'recoil'

export interface RunningTest {
  testName: string
  candidate: {
    id: string
    payLoad: number
  }
}

export interface RunningAbTests {
  runningAbTests: {
    [testName: string]: RunningTest
  }  
}

const RunningAbTestsStore = atom<RunningAbTests>({
  key: 'RunningAbTestsStore',
  default: null
})

export default RunningAbTestsStore
