import React from 'react'

import '@hotmart/cosmos/dist/styles/utilities/sizing.css'
import '@hotmart/cosmos/dist/styles/utilities/text/text.css'
import '@hotmart/cosmos/dist/loading'

type Props = {
  className?: string
}

const Loader = ({ className = '' }: Props) => {
  return (
    <div data-testid="loading" className={`_w-full _text-center ${className}`}>
      <hot-loading data-testid="hot-loading" />
    </div>
  )
}

export default React.memo(Loader)
