import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

type Props = {
  children: React.ReactNode
}

const ApiClientProvider = ({ children }: Props) => <QueryClientProvider client={client}>{children}</QueryClientProvider>

export default ApiClientProvider
