import { useContext } from 'react'
import { OptimizelyContext } from '@optimizely/react-sdk'
import { Optimizely } from './types'

declare const window: Window &
  typeof globalThis & {
    optimizely: Optimizely
  }

/**
 * Custom hook for accessing Optimizely methods.
 *
 * @example
 * // Usage of the `track` method
 * const { optimizely } = useOptimizely();
 * optimizely.track('yourAttributeName');
 */
export default function useOptimizely(): { optimizely: { track: Optimizely['track'] } } {
  const { optimizely: optimizelySdk } = useContext(OptimizelyContext)

  return {
    optimizely: {
      track: (eventName: string) => {
        if (!optimizelySdk?.track) return

        optimizelySdk.track(eventName)
      }
    }
  }
}
