import React from 'react'

import '@hotmart/cosmos/dist/form/form.css'
import '@hotmart/cosmos/dist/form/input-custom.css'
import '@hotmart/cosmos/dist/form/input-radio.css'

import HelperText from '../HelperText'

type Props = {
  id?: string
  label: string
  error?: string
  helperText?: string
  className?: string
  labelClassName?: string
  inputClassName?: string
  // eslint-disable-next-line no-unused-vars
  onChange?: () => void
  value: string
  disabled?: boolean
  checked: boolean
}

const Radio = React.forwardRef(
  (
    {
      id,
      label,
      error,
      helperText,
      disabled,
      className,
      inputClassName,
      labelClassName,
      onChange,
      value,
      checked
    }: Props,
    ref: any
  ) => {
    const attributes = {
      ...(error ? { invalid: '' } : {}),
      ...(disabled ? { disabled: true } : {})
    }

    return (
      <div className={`hot-form hot-form--custom hot-form--radio ${className}`}>
        <input
          ref={ref}
          type="radio"
          className={`hot-form__input ${inputClassName}`}
          id={id}
          value={value}
          checked={checked}
          onChange={onChange}
          {...attributes}
        />
        <label className={`hot-form__label ${labelClassName}`} htmlFor={id}>
          {label}
        </label>
        <HelperText error={error} helperText={helperText} />
      </div>
    )
  }
)

Radio.displayName = 'Radio'

export default Radio
