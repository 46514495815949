import { useAuthStore } from '../index'

/**
 * Format a locale string by converting the part before the underscore to lowercase.
 *
 * @param {string} locale - The locale string to format.
 * @returns {string} - The formatted locale string.
 */
const formatLocale = (locale: string): string => {
  if (typeof locale !== 'string') return ''
  const underscoreIndex = locale.indexOf('_')
  if (underscoreIndex !== -1) {
    return locale.slice(0, underscoreIndex).toLowerCase() + locale.slice(underscoreIndex)
  } else {
    return locale.toLowerCase()
  }
}

/**
 * Custom hook to retrieve and format locale information from the user's authentication profile.
 *
 * @returns {object} An object containing locale information.
 * @returns {string} return.locale - The original locale string (e.g., "PT_BR").
 * @returns {string} return.currencyFormatLocale - The locale string formatted for currency (e.g., "pt-BR").
 * @returns {string} return.dateFormatLocale - The locale string formatted for dates (e.g., "pt").
 * @returns {string} return.checkoutFormattedLocale - The locale string formatted for checkout purposes (e.g., "pt_BR").
 */
export default function useLocales() {
  const [UserAuthValue] = useAuthStore()

  const locale = UserAuthValue?.profile?.locale.toString() 
  const currencyFormatLocale = locale.replace('_', '-') 
  const dateFormatLocale = locale.split('_')[0].toLowerCase() 
  const checkoutFormattedLocale = formatLocale(locale) 

  return { locale, currencyFormatLocale, checkoutFormattedLocale, dateFormatLocale }
}
