import React from 'react'
import '@hotmart/cosmos/dist/form/input-validate.css'
import './style.scss'

type Props = {
  error: string
  helperText: string
}

const HelperText = ({ error, helperText }: Props) => (
  <>
    {error && <div className="invalid-feedback d-block">{error}</div>}
    {!error && helperText && <div className="helper-text">{helperText}</div>}
  </>
)
export default HelperText
