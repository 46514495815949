import { format, parse } from 'date-fns'

import pt from 'date-fns/locale/pt-BR'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import ja from 'date-fns/locale/ja'
import it from 'date-fns/locale/it'

const locales = { pt, en, es, fr, ja, it }

export enum FormatDateOptions {
  DAY_AND_MONTH = 'dd/MM', // 29/04
  DAY_OF_MONTH_dd = 'dd', // 12
  DAY_OF_WEEK_EEEE = 'EEEE', // Friday
  LONG_LOCALIZED_DATE_HOUR_MINUTES = 'P kk:mm', // 04/29/1453 15:00
  LONG_LOCALIZED_DATE_P = 'P', // 04/29/1453
  MONTH_FORMATTING_M = 'M', // 4
  YEAR_FORMATTING_Y = 'Y', // 1453
  MONTH_FORMATTING_MMM = 'MMM' // Apr
}

export const qtdDaysOfMonth = ({ month, year }: { month: number; year: number }) => new Date(year, month, 0).getDate()

export const formatDate = (str, locale) => {
  if (!str) return ''
  const now = new Date()
  const date = parse(str, 'yyyy-MM-dd', now)
  const simplifiedLocale = locale.split('_')[0].toLowerCase()
  return format(date, 'PPP', { locale: locales[simplifiedLocale] || en })
}

export const generalFormatDate = (date: number, optionFormat: FormatDateOptions, locale: string) => {
  if (!date) return ''
  return format(date, optionFormat || FormatDateOptions.LONG_LOCALIZED_DATE_P, {
    locale: locales[locale] || en
  })
}
