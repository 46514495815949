import { ShopperPurchaseResponse, SubscriberDetailsResponse } from '@hotmart/hub-api-client/types'
import { EVENT_ACTION, EVENT_ENTITY, sendDataHub } from '@hotmart/hub-components'
import { useAuthStore, useLocales } from '@hotmart/hub-store'

type Action = 'VIEW' | 'CLICK'
type Target =
  | 'purchase_payment'
  | 'reprint_billet'
  | 'purchase-recurrence-retry'
  | 'regularize'
  | 'payment-management'
  | 'regularize-by-pix'
  | 'regularize-by-billet'
  | 'regularize-by-credit-card'
  | 'regularize-back'
  | 'print-billet'
  | 'generate-pix-code'
  | 'payment-by-credit-card'
  | 'regularize-options'
  | 'view-pix-code'
  | 'view-billet'
  | 'main-purchase-list'
  | 'purchase-list'
  | 'purchase-details'
  | 'purchase-list-payment-management'
  | 'purchase-details-payment-management'
  | 'thanks-page'
  | 'thanks-page-credit-card'
  | 'keep-new-credit-card'
  | 'back-previous-credit-card'
  | 'access-content'
  | 'change-payment-type-modal'
  | 'alert-error-back-to-original-payment-type'
  | 'keep-pix-like-payment-method'
  | 'keep-billet-like-payment-method'
  | 'alter-method'
  | 'alter-method-credit-card-form'
  | 'changed-credit-card'
  | 'changed-credit-card-error'
  | 'changed-pix-to-credit-card'
  | 'changed-billet-to-credit-card'
  | 'pay-with-pix'
  | 'choose-another-payment-method'
  | 'credit-card-payment-error'
  | 'cancel-subscription-default'
  | 'cancel-subscription-enotas'
  | 'cancel-subscription-producer'
  | 'change-billing-date'
  | 'change-billing-date-failure'
  | 'change-billing-date-success'
  | 'recurrence-history'
  | 'redirect-alter-method'
  | 'redirect-payment-charge'
  | 'redirect-plan-exchange'
  | 'refund-charge'
  | 'send-payment-charge'

export type InteractEvent = StartEvent & {
  isSmartInstallment: boolean
  paymentType: string
  subscriptionId: number
  utmSource?: string
}

export type StartEvent = {
  action: Action
  target: Target
  productId: number
  purchaseId: number
  status: string
  userId?: number
  locale?: string
  isMobile?: boolean
  isNewHub?: boolean
}

type PropInteractEvent = {
  action: Action
  target: Target
  purchaseStore: ShopperPurchaseResponse
  subscriberStore: SubscriberDetailsResponse
  metadata?: Array<{ key: string; value: string }>
  utmSource?: string
}

type PreFilledInteractEvent = PropInteractEvent & {
  locale: string
  userId: number
}

type PropStartEvent = {
  action: Action
  target: Target
  productId?: number
  purchaseId?: number
  status?: string
}

const isMobile = window?.matchMedia('(max-width: 992px)').matches

function mountInteractEvent({
  action,
  target,
  locale,
  userId,
  purchaseStore,
  subscriberStore,
  utmSource
}: PreFilledInteractEvent): InteractEvent {
  return {
    action,
    target,
    isMobile,
    locale,
    isNewHub: true,
    isSmartInstallment: purchaseStore?.purchase?.smartInstallment,
    paymentType: subscriberStore?.paymentType,
    productId: subscriberStore?.product?.id,
    userId: subscriberStore?.subscriber?.id || userId,
    subscriptionId: subscriberStore?.subscriptionId,
    purchaseId: purchaseStore?.purchase?.id,
    status: subscriberStore?.status,
    utmSource
  }
}

export default function useSendEventsSubscriberDatahub(): [
  (event: PropInteractEvent) => void,
  (event: PropStartEvent) => void
] {
  const { locale } = useLocales()
  const [UserAuthValue] = useAuthStore()
  const userId = Number(UserAuthValue?.profile?.id)

  function sendSubscriberInteractEvents({
    action,
    target,
    purchaseStore,
    subscriberStore,
    metadata,
    utmSource
  }: PropInteractEvent) {
    const baseEvent = mountInteractEvent({ action, target, locale, userId, purchaseStore, subscriberStore, utmSource })

    const event = metadata ? { ...baseEvent, metadata } : baseEvent

    sendDataHub(EVENT_ENTITY.PAYMENT_MANAGEMENT, EVENT_ACTION.INTERACT, '1.0', event)
  }

  function sendSubscriberStartEvents({ action, target, productId, purchaseId, status }: PropStartEvent) {
    const event = {
      action,
      target,
      locale,
      userId,
      productId,
      purchaseId,
      status,
      isMobile,
      isNewHub: true
    }

    sendDataHub(EVENT_ENTITY.PAYMENT_MANAGEMENT, EVENT_ACTION.START, '1.0', event)
  }

  return [sendSubscriberInteractEvents, sendSubscriberStartEvents]
}
