import React, { useEffect, useRef } from 'react'

import '@hotmart/cosmos/dist/modal'
import '@hotmart/cosmos/dist/modal/modal-header'
import '@hotmart/cosmos/dist/modal/modal-body'
import '@hotmart/cosmos/dist/modal/modal-footer'
import '@hotmart/cosmos/dist/button/button.css'
import '@hotmart/cosmos/dist/button/variations/custom.css'

type Action = {
  label: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'success' | 'default'
}

type Props = {
  children?: React.ReactNode
  open?: boolean
  title?: string | JSX.Element
  actions?: Action[]
  footer?: React.ReactNode
  onClose?: () => void
  classHotModal?: string
}

function Modal({ children, title, actions, open, footer, onClose, classHotModal }: Props) {
  const modalRef = useRef(null)

  useEffect(() => {
    if (!modalRef.current || !onClose) return

    modalRef.current.addEventListener('close', onClose)
  }, [onClose])

  useEffect(() => {
    if (!modalRef.current) return

    if (open) modalRef.current.openModal()
    else modalRef.current.closeModal()
  }, [open])

  return (
    <hot-modal ref={modalRef} class={classHotModal}>
      <hot-modal-header data-testid="modal_header">
        <p style={{ fontSize: '1.5rem', fontWeight: 300, margin: 0 }}>{title}</p>
      </hot-modal-header>
      {children && <hot-modal-body>{children}</hot-modal-body>}
      {footer && <hot-modal-footer>{footer}</hot-modal-footer>}
      {actions && actions.length > 0 && (
        <hot-modal-footer>
          {actions.map(({ label, onClick, buttonType }) => (
            <button
              onClick={onClick}
              className={`hot-button${buttonType && buttonType !== 'default' ? `--${buttonType}` : ''} _ml-auto`}
              key={label}
            >
              {label}
            </button>
          ))}
        </hot-modal-footer>
      )}
    </hot-modal>
  )
}

export default Modal
