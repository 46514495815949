/* eslint-disable max-lines */
import { PURCHASE_STATUS } from '@hotmart/hub-components'
import {
  PaginatedResponse,
  PurchaseCommentMutation,
  ShopperPurchaseResponse,
  ShopperPurchaseV2Response,
  HotPayInvoiceResponse,
  HotPayInvoiceDetailMutation,
  ProducerRankingBNResponse,
  RecommendedProductsResponse,
  ShipmentTransactionDetailResponse,
  AvailableProductResponse
} from '../types'
import http from './http'
import {
  hubV1,
  ask,
  hubV2,
  hubV3,
  shopper,
  space,
  refunds,
  bkfSupport,
  hotpayInvoice,
  abTest,
  eticket,
  marketplaceUserProfileUser,
  marketplaceUserAddress,
  cep,
  document,
  security,
  features,
  dataExport,
  content_search,
  account,
  shipmentsV1,
  spaceV1
} from './requests/urls'

/* ***************** SUBSCRIBER **************** */
export * from './requests/subscriber'

/* ***************** PURCHASE **************** */
export const getPurchaseDetail = async (purchaseId: string) => {
  return (await http.get<ShopperPurchaseResponse>(`${hubV1}/${purchaseId}`)).data
}
export const getPurchaseListFreeSignup = async (archived?: string) => {
  return (await http.get(`${hubV1}/free/?archived=${archived || PURCHASE_STATUS.UNARCHIVED}`)).data
}
export const getPurchaseContent = async (purchaseId: number) => {
  return (await http.get(`${hubV1}/${purchaseId}/content`)).data
}
export const getPurchaseComments = async (purchaseId: string) => {
  return (await http.get(`${hubV1}/${purchaseId}/comment`)).data
}
export const getPurchaseRatings = async (productId: string) => {
  return (await http.get(`${ask}/survey/product/${productId}/rating`)).data
}
export const getPurchaseBonus = async (purchaseId: string) => {
  return (await http.get(`${hubV1}/${purchaseId}/bonus`)).data
}

export const postPurchaseComment = async (data: PurchaseCommentMutation) => {
  return http.post(`${hubV1}/${data.purchaseId}/comment`, data)
}
export const postPurchaseRating = async (data: PurchaseCommentMutation) => {
  return http.post(`${hubV1}/${data.purchaseId}/rating`, data)
}
export const postPutchaseLog = async (purchaseId: string) => {
  return http.post(`${hubV1}/${purchaseId}/log`, { purchaseId })
}

export const getPurchaseList = async (archived?: string) => {
  return (
    await http.get<PaginatedResponse<ShopperPurchaseResponse>>(
      `${hubV2}/?archived=${archived || PURCHASE_STATUS.UNARCHIVED}`
    )
  ).data
}
export const getPurchaseDetailV2 = async (productId: string | number) => {
  return (await http.get<ShopperPurchaseV2Response>(`${hubV2}/products/${productId}`)).data
}
export const getPurchaseDownloadLink = async (purchaseId: string, contentId: string) => {
  return (await http.get(`${hubV2}/${purchaseId}/content/${contentId}/download-link`)).data
}

export const getPurchaseCanceledList = async () => {
  return (await http.get(`${hubV3}/`)).data
}

export const getBecomeProducer = async () => {
  return (await http.get(`${shopper}/addRoleProducer`)).data
}

export const getPurchaseDisplayLink = async (sellerId: number) => {
  return (await http.get(`${space}/link`, { headers: { userId: sellerId } })).data
}

export const getRefundProcess = async (purchaseId: number) => {
  return (await http.get(`${refunds}/purchases/${purchaseId}/refundsAsUser`)).data
}

export const getBusinessModelData = async (transactionCode: string) => {
  return (await http.get(`${bkfSupport}/purchase/transaction/${transactionCode}`)).data
}

export const putCancelRefund = async (purchaseId: number) => {
  return http.put(`${refunds}/purchases/${purchaseId}/cancelRefundBuyer`)
}

export const getFileDownload = async (fileParams: { purchaseId: number; contentId: number }) => {
  return (await http.get(`${hubV2}/${fileParams.purchaseId}/content/${fileParams.contentId}/download-link`)).data
}

export const getConfirmFileDownload = async (fileParams: { url: string; token: string }) => {
  return (await http.get(fileParams.url, { headers: { token: fileParams.token } })).data
}

export const getInvoiceValidateData = async (params: { email: string; transaction: string }) => {
  return (
    await http.get<string>(
      `${hotpayInvoice}/open-invoice/validatePurchase?email=${params.email}&transaction=${params.transaction}`
    )
  ).data
}

export const getOpenInvoiceDownloadData = async (params: { transaction: string; email: string }) => {
  return (
    await http.get<string>(
      `${hotpayInvoice}/open-invoice/download-file?transaction=${params.transaction}&email=${params.email}`
    )
  ).data
}

export const getInvoiceDownloadData = async (params: { transaction: string }) => {
  return (await http.get<string>(`${hotpayInvoice}/invoice/download-file?transaction=${params.transaction}`)).data
}

export const postAbTest = async (testId: string, transactionRef: string) => {
  return await http.post(`${abTest}/${testId}/execute?transactionRef=${transactionRef}`)
}

export const getUpgradeTicket = async (purchaseId: string) => {
  return (await http.get(`${eticket}/upgradeOffers/${purchaseId}`)).data
}

export const postUpgradeTicket = async (data: { previousPurchaseId: string; newPurchaseTransaction: string }) => {
  return http.post(`${eticket}/processUpgrade`, data)
}

export const getInvoiceDetail = async (ucode: string, transaction: string) => {
  return (await http.get<HotPayInvoiceResponse>(`${hotpayInvoice}/open-invoice/${transaction}/invoiceDetails/${ucode}`))
    .data
}

export const getInvoiceDetailByEmailAndTransaction = async (transaction: string, email: string) => {
  const { data } = await http.get<HotPayInvoiceResponse>(
    `${hotpayInvoice}/open-invoice/invoiceDetails?email=${email}&transaction=${transaction}`
  )
  return {
    ...data,
    currentUserEmail: email
  }
}

export const putInvoiceDetail = async (data: HotPayInvoiceDetailMutation) => {
  const { currentUserEmail, ...rest } = data
  return http.put(`${hotpayInvoice}/open-invoice?email=${currentUserEmail}`, rest)
}

export const postArchive = async (data: { purchaseId?: number; productId: number; participTicket?: boolean }) => {
  const purchaseIdParam = data?.purchaseId ? `purchaseId=${data?.purchaseId}` : ''
  const eticketParam = data?.participTicket ? `&participTicket=${data?.participTicket}` : ''
  return http.post(`${hubV1}/archive?${purchaseIdParam}&productId=${data.productId}${eticketParam}`)
}

export const deleteArchive = async (data: { purchaseId?: number; productId: number; participTicket?: boolean }) => {
  const purchaseIdParam = data?.purchaseId ? `purchaseId=${data?.purchaseId}` : ''
  const eticketParam = data?.participTicket ? `&participTicket=${data?.participTicket}` : ''
  return http.delete(`${hubV1}/unarchive?${purchaseIdParam}&productId=${data.productId}${eticketParam}`)
}

export const getProducerRankingBN = async () => {
  return (
    await http.get<ProducerRankingBNResponse[]>(`${content_search}/producer/campaign/best/results?locale=PT_BR&size=10`)
  ).data
}

export const getRecommendedProducts = async (locale: string, ucode: string) => {
  return (
    await http.get<RecommendedProductsResponse>(
      `${content_search}/product/hubRecommendation?locale=${locale}&ucode=${ucode}&size=16`
    )
  ).data
}

export const getShipmentTransactionDetail = async (transactionCode: string) => {
  return (await http.get<ShipmentTransactionDetailResponse>(`${shipmentsV1}/transaction/${transactionCode}`)).data
}

export const getAvailableProduct = async () => {
  return (await http.get<AvailableProductResponse[]>(`${spaceV1}/products/latest?sinceAt=48`)).data
}

/* *****************PROFILE **************** */

export const postProfilePhoto = async (data: any) => {
  return http.post(`${marketplaceUserProfileUser}/picture`, data)
}

export const getProfileUser = async () => {
  return (await http.get(`${marketplaceUserProfileUser}`)).data
}

export const getCountry = async () => {
  return (await http.get(`${marketplaceUserAddress}/country`)).data
}

export const getState = async (countryId: string) => {
  return (await http.get(`${marketplaceUserAddress}/state?country=${countryId}`)).data
}

export const getCity = async (state: string) => {
  return (await http.get(`${cep}/states/${state}`)).data
}

export const getAddressByZipCode = async (zipCode: string) => {
  return (await http.get(`${cep}/info/${zipCode}`)).data
}

export const getCountryCode = async (countryId: string) => {
  return (await http.get(`${marketplaceUserAddress}/countryCode?countryId=${countryId}`)).data
}

export const getProfilePersonal = async () => {
  return (await http.get(`${marketplaceUserProfileUser}/personal`)).data
}

export const getProfileDocument = async () => {
  return (await http.get(`${document}/document`)).data
}

export const putProfileDocument = async (data: any) => {
  return http.put(`${marketplaceUserProfileUser}/document`, data)
}

export const putProfile = async (data: any) => {
  return http.put(`${marketplaceUserProfileUser}/profile`, data)
}

export const putProfilePersonal = async (data: any) => {
  return http.put(`${marketplaceUserProfileUser}/personal`, data)
}

export const postProfilePassword = async (data: any) => {
  return http.post(`${security}/user/password/change`, data)
}

export const getPrivacyFeatures = async () => {
  return (await http.get(`${features}/`)).data
}

export const getPrivacyDataExport = async () => {
  return (await http.get(`${dataExport}/?type=xlsx&sendEmail=true`)).data
}

export const deleteProfileDevice = async () => {
  return http.delete(`${security}/user/logoutall`)
}

export const getIsConsumerAccount = async () => {
  return (await http.get(`${account}/rest/v1/consumer/canAccess`)).data
}
