import React from 'react'

import { Pagination, Keyboard } from 'swiper'
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import { HcContainer } from '@hotmart-org-ca/cosmos-web/dist/react/hc-container'
import { clsx } from 'clsx'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/keyboard'
import 'swiper/css/navigation'

const DEFAULT_SLIDES_PER_VIEW = 'auto'

const DEFAULT_PROPS: SwiperProps = {
  modules: [Pagination, Keyboard],
  pagination: {
    clickable: true
  },
  keyboard: { enabled: true },
  spaceBetween: 16
}

type SliderProps = {
  children: React.ReactNode
  slidesPerView?: number | 'auto'
  className?: string
  extraConfig?: Record<string, any>
}

const Slider = ({ children, slidesPerView = DEFAULT_SLIDES_PER_VIEW, className, extraConfig }: SliderProps) => {
  const classNames = clsx([className, '_d-block _overflow-hidden _w-full'])

  return (
    <HcContainer className={classNames}>
      <Swiper className="slider" slidesPerView={slidesPerView} {...DEFAULT_PROPS} {...extraConfig}>
        {children}
      </Swiper>
    </HcContainer>
  )
}

Slider.Item = SwiperSlide

export default Slider
