import { useEffect, useState } from 'react'
import axios from 'axios'
import { useAuthStore } from '@hotmart/hub-store'
import { EVENT_ACTION, EVENT_ENTITY, sendDataHub } from '@hotmart/hub-components'

const http = axios.create()

type Props = {
  children: React.ReactNode
}

const AxiosInterceptor = ({ children }: Props) => {
  const [UserAuthValue] = useAuthStore()
  const [isSet, setIsSet] = useState(false)

  useEffect(() => {
    http.interceptors.request.use(
      async (config) => {
        if (UserAuthValue?.id_token) {
          config.headers.Authorization = `Bearer ${UserAuthValue.id_token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    const errInterceptor = (error) => {
      sendDataHub(EVENT_ENTITY.USER, EVENT_ACTION.CLICK, '1.0', {
        userCode: UserAuthValue?.profile?.ucode || 'publicUser',
        operationType: 'http_request',
        failureMessage: error?.response?.data?.error || error?.response?.data?.error_description || ''
      })
      if (error?.response?.status === 401) {
        window.dispatchEvent(
          new CustomEvent('cas:logout-user', {
            detail: { origin: error?.response?.request?.responseURL, message: error?.response?.data?.error }
          })
        )
      }
      return Promise.reject(error)
    }

    const interceptor = http.interceptors.response.use((response) => response, errInterceptor)
    setIsSet(true)
    return () => http.interceptors.response.eject(interceptor)
  }, [UserAuthValue])

  return <>{isSet && children}</>
}
export default http
export { AxiosInterceptor }
