export const getDeviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'app_tablet'
  } else if (/Android/.test(ua)) {
    return 'app_android'
  } else if (
    /Mobile|iP(hone|od)|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return 'app_ios'
  }
  return 'site_desktop'
}
