import React, { useRef, useEffect, useMemo } from 'react'
import { useToastStore, DefaultToast } from '@hotmart/hub-store'
import { HcAlert } from '@hotmart-org-ca/cosmos-web/dist/react/hc-alert'
import { Trans } from 'react-i18next'
import { useAlertDismiss } from '../custom-hooks'

const Toast = () => {
  const toastRef = useRef(null)

  const [toastStore, setToastStore] = useToastStore()

  const { context, message, onTimeout, onDismiss, dismissible, timeout, translationComponent } = toastStore

  const showToast = useMemo(() => message, [message])

  useEffect(() => {
    let toastTimeout = null

    clearTimeout(toastTimeout)
    toastTimeout = setTimeout(() => {
      setToastStore(DefaultToast)
      if (onTimeout && typeof onTimeout === 'function') {
        onTimeout()
      }
    }, timeout || 3000)

    return () => clearTimeout(toastTimeout)
  }, [toastStore, setToastStore, onTimeout, timeout])

  useAlertDismiss(toastRef, () => {
    setToastStore(DefaultToast)
    if (onDismiss && typeof onDismiss === 'function') {
      onDismiss()
    }
  })

  return (
    showToast && (
      <div id="alert-wrapper" className="toast">
        <HcAlert ref={toastRef} dismissible={dismissible} dismissButtonAriaLabel="dismiss alert" context={context}>
          {translationComponent ? <Trans i18nKey={message} components={[translationComponent]} /> : message}
        </HcAlert>
      </div>
    )
  )
}

export default Toast
