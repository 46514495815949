import EventAgentJS from '@hotmart/event-agent-js'

export type ISendDataHub = (
  entity: EVENT_ENTITY,
  action: EVENT_ACTION,
  data_version: string,
  event: object,
  system?: EVENT_SYSTEM
) => Promise<Event>

export const sendDataHub: ISendDataHub = (
  entity,
  action,
  data_version = '1.0',
  event = {},
  system = EVENT_SYSTEM.HUB_UI
) => {
  EventAgentJS.mode = process.env.NODE_ENV === 'production' ? 'production' : 'staging'
  return EventAgentJS.send({
    system: system,
    entity,
    action,
    data_version,
    event_version: '1.1',
    event
  })
}

export enum EVENT_ENTITY {
  USER = 'user',
  PRODUCT = 'product',
  PRODUCTS_RECOMENDED = 'products_recomended',
  MODAL_PRODUCT_PAYMENT_CONFIGURATION = 'modal_product_paymentconfiguration',
  MODAL_PRODUCT_CONTENT_ACCESS = 'modal_product_contentaccess',
  MODAL_PURCHASES_PRODUCT = 'home_purchases_product',
  SIGNUP = 'signup',
  PAYMENT_MANAGEMENT = 'payment_management'
}

export enum EVENT_ACTION {
  CLICK = 'click',
  SOLICITATION = 'solicitation',
  INTERACT = 'interact',
  START = 'start'
}

export enum EVENT_SYSTEM {
  HUB_UI = 'hub_ui',
  API_HTM = 'api_htm'
}
