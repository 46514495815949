import React, { useState, useEffect } from 'react'
import Icons from '../Icons'

import './style.scss'

type Props = {
  title: any
  children: any
  open: boolean
  // eslint-disable-next-line no-unused-vars
  onChange?: (isOpen: boolean) => void
}

const Accordion = ({ title, children, open, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
    if (onChange) onChange(!isOpen)
  }

  return (
    <div className="accordion_container">
      <div className="accordion_container__header">
        <div className="accordion_container__title-wrapper">
          <div className="accordion_container__title-wrapper__content">{title}</div>
          <Icons
            onClick={() => toggleIsOpen()}
            iconName={isOpen ? 'chevron-up' : 'chevron-down'}
            prefix="fal"
            className="accordion_container__toogle"
          />
        </div>
      </div>
      {isOpen && (
        <div className="accordion_container__content" data-testid="accordion-content">
          {typeof children === 'string' ? children : React.cloneElement(children)}
        </div>
      )}
    </div>
  )
}

export default Accordion
