import React from 'react'
import { Link } from 'react-router-dom'

import '@hotmart/cosmos/dist/breadcrumb/breadcrumb.css'

import './style.scss'

type Props = {
  history: Array<{
    active: boolean
    href: string
    name: string
    onClick?(): void
  }>
}

const Breadcrumb = ({ history }: Props) => (
  <hot-breadcrumb>
    {history.map((item) => {
      const { active, href, name } = item

      const attributes = {
        ...(active ? { active: '' } : {})
      }

      return (
        <hot-breadcrumb-item key={name} {...attributes}>
          {!active && href ? (
            <Link to={href} onClick={item.onClick}>
              {name}
            </Link>
          ) : (
            name
          )}
        </hot-breadcrumb-item>
      )
    })}
  </hot-breadcrumb>
)

export default Breadcrumb
