import React from 'react'
import Icons from '../Icons'

import './style.scss'

type Props = {
  title: string
  onClick(): void
}

const BackButton = ({ title, onClick }: Props) => {
  return (
    <div className="backbutton" onClick={() => onClick()}>
      <Icons iconName="chevron-left" prefix="fal" className="backbutton__icon" />
      <div className="backbutton__title">{title}</div>
    </div>
  )
}

export default BackButton
