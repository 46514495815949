import React, { Component } from 'react'
import { captureException } from '@sentry/browser'

import ErrorGeneric from './ErrorGeneric'

export interface Props {
  sentryTag: { name: string; release: string }
  children: React.ReactNode
  isModal?: boolean
  userId?: number
}

class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
    error: null
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error) {
    captureException(error, (scope) => {
      scope.setTags({
        feature: this.props.sentryTag.name,
        release: this.props.sentryTag.release,
        userId: this.props?.userId
      })
      return scope
    })
    console.warn(error)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorGeneric isModal={this.props?.isModal} />
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
