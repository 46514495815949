declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export const sendAnalyticsEvent = (analyticsEvent) => {
  try {
    if (typeof window !== 'undefined' && analyticsEvent) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(analyticsEvent)
    }
  } catch (err) {}
}
