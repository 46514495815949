import React, { ReactNode } from 'react'

import '@hotmart/cosmos/dist/form/form.css'
import '@hotmart/cosmos/dist/form/input-custom.css'
import '@hotmart/cosmos/dist/form/input-checkbox.css'

import HelperText from '../HelperText'

type Props = {
  id?: string
  label?: string | ReactNode
  checked?: boolean
  error?: string
  helperText?: string
  disabled?: boolean
  className?: string
  inputClassName?: string
  labelClassName?: string
  onChange?: () => void
}

const Checkbox = React.forwardRef(
  (
    { id, label, checked, error, helperText, disabled, className, inputClassName, labelClassName, onChange }: Props,
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const attributes = {
      ...(error ? { invalid: '' } : {}),
      ...(disabled ? { disabled: true } : {}),
      ...(checked ? { checked: true } : { checked: false })
    }

    return (
      <div className={`hot-form hot-form--custom hot-form--checkbox ${className}`}>
        <input
          ref={ref}
          type="checkbox"
          className={`hot-form__input ${inputClassName}`}
          id={id}
          {...attributes}
          onChange={onChange}
        />
        <label className={'hot-form__label'} htmlFor={id}>
          <span className={labelClassName}>{label}</span>
        </label>
        <HelperText error={error} helperText={helperText} />
      </div>
    )
  }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
