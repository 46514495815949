import React from 'react'
import '@hotmart/cosmos/dist/tooltip'

import Icons from '../Icons'

import './style.scss'

type Props = {
  text?: string
  isChecked?: boolean
  isRequired?: boolean
  requiredMessage?: string
  className?: string
  tooltipText?: string
}

const Label = ({ text, isChecked, isRequired, requiredMessage, className, tooltipText, ...props }: Props) => {
  const helperTooltipRender = () => {
    return (
      <hot-tooltip content={tooltipText} position="top">
        <Icons className="tooltip-icon" iconName="info-circle" prefix="fal" />
      </hot-tooltip>
    )
  }

  return (
    <div className={className} {...props}>
      {text}
      {tooltipText && helperTooltipRender()}
      {isChecked && <Icons className="label-checked" iconName="check-circle" prefix="fal" />}
      {isRequired && <span className="label__required">{requiredMessage}</span>}
    </div>
  )
}
export default Label
